import { CollectionData } from "./collections";

import shadesOfGrey1 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-01.jpg";
import shadesOfGrey2 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-02.jpg";
import shadesOfGrey3 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-03.jpg";
import shadesOfGrey4 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-04.jpg";
import shadesOfGrey5 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-05.jpg";
import shadesOfGrey6 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-06.jpg";
import shadesOfGrey7 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-07.jpg";
import shadesOfGrey8 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-08.jpg";
import shadesOfGrey9 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-09.jpg";
import shadesOfGrey10 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-10.jpg";
import shadesOfGrey11 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-11.jpg";
import shadesOfGrey12 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-12.jpg";
import shadesOfGrey13 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-13.jpg";
import shadesOfGrey14 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-14.jpg";
import shadesOfGrey15 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-15.jpg";
import shadesOfGrey16 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-16.jpg";
import shadesOfGrey17 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-17.jpg";
import shadesOfGrey18 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-18.jpg";
import shadesOfGrey19 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-19.jpg";
import shadesOfGrey20 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-20.jpg";
import shadesOfGrey21 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-21.jpg";
import shadesOfGrey22 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-22.jpg";
import shadesOfGrey23 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-23.jpg";
import shadesOfGrey24 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-24.jpg";
import shadesOfGrey25 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-25.jpg";
import shadesOfGrey26 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-26.jpg";
import shadesOfGrey27 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-27.jpg";
import shadesOfGrey28 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-28.jpg";
import shadesOfGrey29 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-29.jpg";
import shadesOfGrey30 from "/public/images/collections/2024-shades-of-grey/ALEXANDRA-GREY-SHADES-OF-GREY-30.jpg";

export const shadesOfGreyDescriptionParagraphs = [
  "Shades of Grey célèbre l'art des nuances et des contrastes à travers une collection aux textures riches et aux silhouettes contemporaines. Chaque création joue avec la lumière et l'ombre, créant une symphonie visuelle entre force et délicatesse.",
  "Les matières nobles se parent de motifs graphiques sophistiqués, tandis que la palette de gris s'étend du blanc lumineux aux tonalités les plus profondes. Cette collection propose une garde-robe versatile où l'élégance casual côtoie le raffinement des tenues de soirée.",
  "Une ode à la femme moderne qui embrasse toutes ses facettes, de l'allure décontractée au glamour assumé. Shades of Grey réinvente les codes du luxe contemporain en célébrant la liberté d'expression à travers une mode intemporelle et audacieuse.",
];

export const shadesOfGreyCollection: CollectionData = {
  id: "2024-shades-of-grey",
  title: "Shades of Grey",
  descriptionParagraphs: shadesOfGreyDescriptionParagraphs,
  images: [
    shadesOfGrey1,
    shadesOfGrey2,
    shadesOfGrey3,
    shadesOfGrey4,
    shadesOfGrey5,
    shadesOfGrey6,
    shadesOfGrey7,
    shadesOfGrey8,
    shadesOfGrey9,
    shadesOfGrey10,
    shadesOfGrey11,
    shadesOfGrey12,
    shadesOfGrey13,
    shadesOfGrey14,
    shadesOfGrey15,
    shadesOfGrey16,
    shadesOfGrey17,
    shadesOfGrey18,
    shadesOfGrey19,
    shadesOfGrey20,
    shadesOfGrey21,
    shadesOfGrey22,
    shadesOfGrey23,
    shadesOfGrey24,
    shadesOfGrey25,
    shadesOfGrey26,
    shadesOfGrey27,
    shadesOfGrey28,
    shadesOfGrey29,
    shadesOfGrey30,
  ],
  date: "09/24",
  bannerImage: shadesOfGrey1,
};
