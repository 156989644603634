import { StaticImageData } from "next/image";

import { darkGreyCollection } from "./2021-dark-grey";
import { lightGreyCollection } from "./2021-light-grey";
import { casaDelGreyCollection } from "./2022-casa-del-grey";
import { splashCollection } from "./2022-splash";
import { naturalGreyCollection } from "./2023-natural-grey";
import { sixtyGreyCollection } from "./2023-60s-grey";
import { shadesOfGreyCollection } from "./2024-shades-of-grey";

export interface CollectionData {
  id: string;
  title: string;
  descriptionParagraphs: string[];
  images: StaticImageData[];
  date: string;
  bannerImage: StaticImageData;
}

// Fonction pour convertir la date du format "MM/YY" en Date
const parseCollectionDate = (dateStr: string): Date => {
  const [month, year] = dateStr.split("/");
  return new Date(2000 + parseInt(year), parseInt(month) - 1);
};

// Trier les collections par date (du plus récent au plus ancien)
const sortedCollections = [
  casaDelGreyCollection,
  darkGreyCollection,
  lightGreyCollection,
  splashCollection,
  naturalGreyCollection,
  sixtyGreyCollection,
  shadesOfGreyCollection,
].sort((a, b) => {
  const dateA = parseCollectionDate(a.date);
  const dateB = parseCollectionDate(b.date);
  return dateB.getTime() - dateA.getTime();
});

export const Collections: CollectionData[] = sortedCollections;
